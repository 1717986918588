<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <users
    class="mt-6 ml-6 mr-4"
    :page="Number(page)"
    :itemsPerPage="Number(itemsPerPage)"
    :search="search"
    :sortBy="[sortBy]"
    :sortDesc="[sortDesc && sortDesc.length > 0 ? sortDesc === 'true' : []]"
    :type="type ? type : 'local'"
  />
</template>
<script>
import Users from '@/components/users/Users.vue';

export default {
  props: ['page', 'itemsPerPage', 'search', 'sortBy', 'sortDesc', 'type'],
  components: {
    Users,
  },
};
</script>
